import Navbar from "../components/Navbar"
import Askaptbook from "../components/Askaptbook";
import languageContext from "../context/language/languageContext";
import { useContext, useEffect, useState } from "react";    
const Booking = ()=>{
    console.log("Booking")
    const { bookappointment,setbookappointment } = useContext(languageContext);
    useEffect(()=>{
        setbookappointment("Book My Next Appointment")
    }, [])
    return(
        <>
        <Navbar/>
        <div className="w-full overflow-y-auto"> {/* Make this section scrollable */}
                    < Askaptbook bookappointment={bookappointment} setbookappointment={setbookappointment}/>
                </div>

        </>
    )
}
export default Booking